<template>
  <div class="debit-tabs">
    <section>
      <div class="datatable-toolbar">
        <div class="buttons">
          <button
            class="button h-button is-success is-raised"
            @click.prevent="cariChannelPda"
          >
            <i class="fas fa-search"></i>
            Cari Data
          </button>
        </div>
      </div>
    </section>
    <br />
    <section class="columns">
      <div class="column is-12 tma">
        <div class="card-debit">
          <div class="card">
            <div class="card-header"></div>
            <div class="card-conten chart">
              <v-chart autoresize class="chart" :option="awlr" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <br />
    <section class="channel">
      <div class="tabel-channel">
        <div class="table-wrapper" v-if="isLoading">
          <app-spinner />
        </div>
        <div class="table-wrapper" v-else>
          <b-table
            class="table is-datatable is-hoverable table-is-bordered"
            :data="pda"
            :columns="columnsPda"
            focusable
            paginated
            :total="totalPda"
            :per-page="perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
            icon-pack="fas"
            icon-left="chevron-right"
          >
            <b-table-column field="tanggal" label="Tanggal" v-slot="props">
              {{ props.row.tanggal }}
            </b-table-column>

            <b-table-column field="ch1" label="TMA (cm)" v-slot="props">
              {{ props.row.ch1 }}
            </b-table-column>

            <template #empty>
              <div class="has-text-centered">Tidak Ada Data</div>
            </template>
          </b-table>
        </div>
      </div>
    </section>
    <br />
  </div>
</template>

<script>
let sseClient;
import { mapActions, mapState } from "vuex";
import AppSpinner from "../shared/AppSpinner.vue";
import VChart from "vue-echarts";
export default {
  name: "ModalTablePda",
  components: {
    AppSpinner,
    VChart,
  },
  props: {
    registerId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      pda: [],
      totalPda: 0,
      perPage: 10,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      columnsPda: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "ch1",
          label: "TMA (cm)",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: true,
      hasMobileCards: true,
      awlr: {},
      waspada: 5,
      siaga: 50,
      awas: 60,
      tinggiTanggul: 150,
      sn: null,
    };
  },
  created() {
    this.isLoading = true;
    this.fetchLevelByRegisterId(this.registerId).then((resp) => {
      this.waspada = resp.waspada;
      this.siaga = resp.siaga;
      this.awas = resp.awas;
      this.tinggiTanggul = resp.tinggiTanggul;
    });
    this.fetchLandingPdaChannel(this.registerId).then(() => {
      this.landingChannel.slice().forEach((item) => {
        this.sn = item.serialNumber;
      });
    });
    this.loadAsyncData();
  },
  computed: {
    ...mapState({
      landingChannel: (state) => state.landingChannel.items,
      landingLevel: (state) => state.landingLevel.item,
    }),
  },
  methods: {
    ...mapActions("landingChannel", ["fetchLandingPdaChannel", "addChannel"]),
    ...mapActions("landingLevel", ["fetchLevelByRegisterId"]),
    loadSubcribe() {
      sseClient = this.$sse.create({
        url: "/socket/hours/subscribe",
        format: "json",
        withCredentials: false,
        polyfill: true,
      });

      // Catch any errors (ie. lost connections, etc.)
      sseClient.on("error", (e) => {
        console.error("lost connection or failed to parse!", e);

        // If this error is due to an unexpected disconnection, EventSource will
        // automatically attempt to reconnect indefinitely. You will _not_ need to
        // re-add your handlers.
      });

      // Handle messages without a specific event
      sseClient.on("message", this.message);

      // Handle 'chat' messages
      sseClient.on("hours", (msg) => {
        // console.log(msg)
        // console.log(this.sn)
        if (msg.serialNumber === this.sn) {
          this.$store.dispatch("channel/addPda", msg);

          this.awlr = {
            title: {
              left: "1%",
            },
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "7%",
              right: "15%",
              bottom: "10%",
              top: "10%",
            },
            xAxis: {
              data: this.landingChannel.map((r) => r.tanggal),
            },
            yAxis: {
              type: "value",
              axisLabel: {
                formatter: "{value} cm",
              },
            },

            dataZoom: [
              {
                startValue: "1970-01-01",
              },
              {
                type: "inside",
              },
            ],
            visualMap: {
              top: 10,
              right: 10,
              pieces: [
                {
                  lte: this.waspada,
                  color: "#63b208",
                },
                {
                  lte: this.siaga,
                  color: "#f7f711",
                },
                {
                  lte: this.awas,
                  color: "#FD0100",
                },
                {
                  gt: this.tinggiTanggul,
                  color: "#7f8c8d",
                },
              ],
              outOfRange: {
                color: "#e74c3c",
              },
            },
            series: {
              name: "TMA",
              type: "line",
              data: this.landingChannel.map((r) => r.ch1),
              markLine: {
                silent: false,
                lineStyle: {
                  color: "#333",
                },
                data: [
                  {
                    yAxis: this.waspada,
                    label: {
                      show: true,
                      position: "middle",
                      formatter: "WASPADA",
                    },
                    lineStyle: {
                      color: "#63b208",
                      width: 2,
                    },
                  },
                  {
                    yAxis: this.siaga,
                    label: {
                      show: true,
                      position: "middle",
                      formatter: "SIAGA",
                    },
                    lineStyle: {
                      color: "#f7f711",
                      width: 2,
                    },
                  },
                  {
                    yAxis: this.awas,
                    label: {
                      show: true,
                      position: "middle",
                      formatter: "AWAS",
                    },
                    lineStyle: {
                      color: "#FD0100",
                      width: 2,
                    },
                  },
                  {
                    yAxis: this.tinggiTanggul,
                    label: {
                      show: true,
                      position: "middle",
                      formatter: "TINGGI TANGGUL",
                    },
                    lineStyle: {
                      color: "#7f8c8d",
                      width: 2,
                    },
                  },
                ],
              },
            },
          };
        }
      });

      sseClient
        .connect()
        .then((sse) => {
          console.log("Data Realtime Hours connected!");

          // Unsubscribes from event-less messages after 7 seconds
          setTimeout(() => {
            sseClient.off("message", this.message);
            // console.log("Stopped listening to event-less messages!");
          }, 7000);

          // Unsubscribes from chat messages after 14 seconds
          setTimeout(() => {
            sse.off("channel", this.data);
            // console.log("Stopped listening to Pda Monitoring messages!");
          }, 14000);
        })
        .catch((err) => {
          // When this error is caught, it means the initial connection to the
          // events server failed.  No automatic attempts to reconnect will be made.
          console.error("Failed to connect to server", err);
        });
    },
    /*
     * Load async data
     */
    loadAsyncData() {
      this.fetchLandingPdaChannel(this.registerId).then(() => {
        this.data = [];

        let currentTotal = this.landingChannel.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.total = currentTotal;
        this.landingChannel
          .slice()
          .reverse()
          .forEach((item) => {
            this.isLoading = false;
            this.pda.push(item);
          });

        this.awlr = {
          title: {
            left: "1%",
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "7%",
            right: "15%",
            bottom: "10%",
            top: "10%",
          },
          xAxis: {
            data: this.landingChannel.map((r) => r.tanggal),
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value} cm",
            },
          },

          dataZoom: [
            {
              startValue: "1970-01-01",
            },
            {
              type: "inside",
            },
          ],
          visualMap: {
            top: 10,
            right: 10,
            pieces: [
              {
                lte: this.waspada,
                color: "#63b208",
              },
              {
                lte: this.siaga,
                color: "#f7f711",
              },
              {
                lte: this.awas,
                color: "#FD0100",
              },
              {
                gt: this.tinggiTanggul,
                color: "#7f8c8d",
              },
            ],
            outOfRange: {
              color: "#e74c3c",
            },
          },
          series: {
            name: "TMA",
            type: "line",
            data: this.landingChannel.map((r) => r.ch1),
            markLine: {
              silent: false,
              lineStyle: {
                color: "#333",
              },
              data: [
                {
                  yAxis: this.waspada,
                  label: {
                    show: true,
                    position: "middle",
                    formatter: "WASPADA",
                  },
                  lineStyle: {
                    color: "#63b208",
                    width: 2,
                  },
                },
                {
                  yAxis: this.siaga,
                  label: {
                    show: true,
                    position: "middle",
                    formatter: "SIAGA",
                  },
                  lineStyle: {
                    color: "#f7f711",
                    width: 2,
                  },
                },
                {
                  yAxis: this.awas,
                  label: {
                    show: true,
                    position: "middle",
                    formatter: "AWAS",
                  },
                  lineStyle: {
                    color: "#FD0100",
                    width: 2,
                  },
                },
                {
                  yAxis: this.tinggiTanggul,
                  label: {
                    show: true,
                    position: "middle",
                    formatter: "TINGGI TANGGUL",
                  },
                  lineStyle: {
                    color: "#7f8c8d",
                    width: 2,
                  },
                },
              ],
            },
          },
        };
      });
    },
    addChannelMethod(chan) {
      this.addChannel(chan);
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cariChannelPda() {
      this.$router.push(`/caridata/telemetry/pda/${this.registerId}`);
    },
  },
  beforeDestroy() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    sseClient.disconnect();

    // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
    // and the SSEManager would have automatically disconnected during beforeDestroy.
  },
  mounted() {
    this.loadAsyncData();
    this.loadSubcribe();
  },
};
</script>

<style scoped>
.fa-search {
  margin-right: 10px;
}
.banting_kanan {
  float: right;
}
.chart {
  height: 400px;
  width: 100%;
}
</style>
