<template>
  <div class="login">
    <div class="form-container">
      <div class="col col-2">
        <div class="login-form">
          <div class="img">
            <img src="../../assets/img/logoPu.png" class="logo" />
          </div>
          <div class="form-title">
            <span class="title-header">
              Selamat Datang
              <br />
              Di BBWS Cimanuk Cisanggarung</span
            >
          </div>
          <div class="form-inputs">
            <div class="field">
              <div class="control has-icons-left">
                <div class="input-box">
                  <label for="password">Username</label>
                  <input
                    v-model="form.username"
                    @blur="$v.form.username.$touch()"
                    type="text"
                    class="input is-focused"
                    placeholder="Username"
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
                <div v-if="$v.form.username.$error" class="help danger-text">
                  <p v-if="!$v.form.username.required" class="help danger-text">
                    username harus diisi
                  </p>
                </div>
              </div>
            </div>
            <br />

            <div class="field">
              <div class="control has-icons-left">
                <div class="input-box">
                  <label for="password">Password</label>
                  <span class="icons">
                    <img
                      class="img2"
                      v-if="show"
                      @click="show = !show"
                      src="../../assets/image/eye.png"
                    />
                    <img
                      class="img1"
                      v-else
                      @click="show = !show"
                      src="../../assets/image/eye-slash.png"
                    />
                  </span>
                  <input
                    v-model="form.password"
                    @blur="$v.form.password.$touch()"
                    :type="show === true ? 'text' : 'password'"
                    class="input is-focused"
                    placeholder="Password"
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
                <div v-if="$v.form.password.$error" class="help danger-text">
                  <p v-if="!$v.form.password.required" class="help danger-text">
                    Password harus diisi
                  </p>
                </div>
              </div>
            </div>

            <div class="input-box buttons">
              <button
                class="button is-medium is-black is-bold is-fullwidth is-focused"
                @click.prevent="login"
                :disabled="isFormInvalid"
              >
                <span class="btn-label">Masuk</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grup">
      <router-link to="/">
        <span class="btn-title">
          <i class="fa fa-angle-double-left" aria-hidden="true"></i>
          Kembali ke Beranda</span
        ></router-link
      >
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ApiMethods from "../../conf/config";

export default {
  name: "login",
  components: {
    // VueHcaptcha,
  },
  data() {
    return {
      show: false,
      form: {
        grantType: "password",
        clientId: ApiMethods.CLIENTID,
        clientSecret: ApiMethods.CLIENTSECRET,
        username: null,
        password: null,
      },
      authority: null,
    };
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },

  methods: {
    login() {
      this.$v.form.$touch();
      this.$store
        .dispatch("auth/loginWithUsernameAndPassword", this.form)
        .then((response) => {
          if (response.authorityType == "DINAS") {
            this.$toasted.success("Pengguna Berhasil Masuk", {
              duration: 3000,
            });
            this.$router.push("/dashboard/dinas");
          } else if (response.authorityType != "PENGAMAT") {
            this.$toasted.success("Pengguna Berhasil Masuk", {
              duration: 3000,
            });
            this.$router.push("/dashboard/profile");
          } else {
            this.$toasted.error("Harap Masuk Menggunakan mobile app", {
              duration: 3000,
            });
            this.form.username = null;
            this.form.password = null;
          }
        })
        .catch((error) => {
          this.$toasted.error(error, { duration: 6000 });
        });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
/* ======Body==== */
.login {
  background: linear-gradient(90deg, #e1b12c 10%, #f9ca24 35%, #192a56 65%);
  /* background: rgb(33, 44, 95);
  background: linear-gradient(90deg, rgba(33, 44, 95, 1), rgba(255, 193, 7, 1)); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 0px;
}

/* ======Form==== */
.form-container {
  display: flex;
  width: 500px;
  height: 600px;
  background: rgba(255, 255, 255, 0.288);
  backdrop-filter: blur(30px);
  border: 3px solid #e5e8e82f;
  box-shadow: 0 0 10px rgba(10, 10, 10, 0.522);
  border-radius: 30px;
}

.form-container::before {
  content: "";
  position: absolute;
  inset: 2px;
  border: 4px solid #212c5f;
  border-radius: 30px;
}

.col-2 {
  position: relative;
  width: 100%;
  padding: 15px;
}

.img {
  display: flex;
  justify-content: center;
}
.logo {
  height: 85px;
  width: 85px;
  margin-top: 10px;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  transition: 0.3s;
}
.form-title {
  color: #fff;
  margin-top: 15px;
  font-size: 17px;
  font-weight: 600;
}
.title-header {
  margin-left: 70px;
}
.form-inputs {
  width: 100%;
}

label {
  display: block;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.input {
  width: 100%;
  height: 45px;
  padding: 0 15px;
  margin: 10px 0;
  color: #212c5f;
  background: rgba(255, 255, 255, 0.421);
  border: none;
  border-radius: 10px;
  outline: none;
  backdrop-filter: blur(30px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.427);
}

.icons {
  position: absolute;
  margin-top: 12px;
  margin-left: 385px;
  cursor: pointer;
  z-index: 10000;
}
/* .danger-text {
  font-size: 14px;
  color: #ffc107;
} */
.img1 {
  padding-top: 12px;
}
.img2 {
  padding-top: 13px;
  margin-left: 4px;
}

::placeholder {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.input-box i {
  position: absolute;
  margin-top: 75px;
  color: #212c5f;
}
.grup {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  margin-left: 10px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.btn-title {
  color: #fff;
}
/* .btn-title:hover {
  color: #212c5f;
} */
.btn-label {
  font-size: 16px;
  font-weight: 600;
}
.btn-title .fa {
  margin-right: 10px;
}
.buttons {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .login {
    margin-top: -25px;
    padding: 0 10px;
  }
  .form-container {
    width: 400px;
  }

  .col-2 {
    width: 100%;
  }
  .icons {
    position: absolute;
    margin-top: 12px;
    margin-left: 210px;
    cursor: pointer;
    z-index: 10000;
  }
  .grup {
    margin-top: 10px;
  }
}
</style>
