<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Data User Activity</h1>
            </div>
          </div>

          <!-- header star -->
          <div class="page-content-inner">
            <div class="lifestyle-dashboard lifestyle-dashboard-v4">
              <div class="columns">
                <div class="column is-12">
                  <!--Header-->

                  <div class="illustration-header-2">
                    <div class="header-image">
                      <img
                        src="../../assets/img/illustrations/placeholders/search-1.svg"
                        alt=""
                        width="200"
                      />
                    </div>
                    <div class="header-meta">
                      <h3>Data User Activity.</h3>
                      <p>
                        Data User Activity adalah data yang diambil dari setiap
                        user yang melakukan aktivitas di halaman web.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <!-- header end -->

          <!-- Chart star -->
          <div
            class="business-dashboard company-dashboard ecommerce-dashboard-v1"
          >
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="dashboard-card is-base-chart">
                  <!-- <div class="content-box"></div> -->
                  <div class="chart-container">
                    <v-chart autoresize class="chart" :option="aktivitas" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <!-- Chart End -->

          <!-- <div class="list-flex-toolbar">
            <div class="control has-icon">
              <input
                class="input custom-text-filter"
                placeholder="Search clientHost"
              />
              <div class="form-icon">
                <i class="fa fa-search"></i>
              </div>
            </div>
          </div> -->

          <div class="page-content-inner">
            <div class="table-wrapper">
              <b-table
                class="table is-datatable is-hoverable table-is-bordered"
                :data="isEmpty ? [] : data"
                :columns="columnsData"
                focusable
                paginated
                :total="totalData"
                :per-page="perPage"
                @page-change="onPageChange"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"
                @sort="onSort"
                icon-pack="fas"
                icon-left="chevron-right"
              >
                <b-table-column field="created" label="Tanggal" v-slot="props">
                  {{ props.row.created }}
                </b-table-column>
                <b-table-column
                  field="activity"
                  label="Aktivitas"
                  v-slot="props"
                >
                  {{ props.row.activity }}
                </b-table-column>
                <b-table-column
                  field="clientHost"
                  label="Klien tHost"
                  v-slot="props"
                >
                  {{ props.row.clientHost }}
                </b-table-column>
                <b-table-column field="country" label="Negara" v-slot="props">
                  {{ props.row.country }}
                </b-table-column>
                <b-table-column
                  field="provider"
                  label="Provider"
                  v-slot="props"
                >
                  {{ props.row.provider }}
                </b-table-column>

                <template #empty>
                  <div class="has-text-centered">
                    Tidak Ada Data Yang Ditampilkan
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import VChart from "vue-echarts";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";

export default {
  name: "ActivityList",
  components: {
    VChart,
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
  },
  data() {
    return {
      data: [],
      totalData: 0,
      perPage: 10,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      columnsData: [
        {
          field: "created",
          label: "Tanggal",
        },
        {
          field: "activity",
          label: "Aktivitas",
        },
        {
          field: "clientHost",
          label: "Klient Host",
        },
        {
          field: "country",
          label: "Negara",
        },
        {
          field: "provider",
          label: "Provider",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: true,
      hasMobileCards: true,
      aktivitas: {},
    };
  },
  created() {
    this.fetchActivity().then(() => {
      this.aktivitas = {
        title: {
          text: "Prosentase Pengunjung Portal",
          subtext: "Source: hkacimancis.info",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "Aktivitas Pengguna",
            type: "pie",
            radius: "70%",
            data: [
              { value: 1048, name: "Landing" },
              { value: 580, name: "Kualitas Air" },
              { value: 735, name: "Pos Pengelola Dinas" },
              { value: 484, name: "Pos Dinas" },
              { value: 300, name: "Detail Pos Dinas" },
              { value: 300, name: "Form Kepuasan" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    });
  },
  computed: {
    ...mapState({
      activities: (state) => state.activity.items,
    }),
  },
  methods: {
    ...mapActions("activity", ["fetchActivity", "searchActivity"]),

    loadAsyncData() {
      this.fetchActivity().then((resp) => {
        this.data = [];
        let currentTotal = resp.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.totalData = currentTotal;
        resp.forEach((item) => {
            item.created = moment(String(item.created)).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            this.data.push(item);
          });
      });
    },

    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },

    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style scoped>
.headers {
  font-size: 20px;
}
.chart-container {
  height: 500px;
  width: 100%;
}
.chart {
  margin-top: 10px;
}
.item-name {
  text-align: justify;
}

.fal {
  margin-right: 10px;
  font-weight: 400;
}

@media (max-width: 768px) {
}
</style>
