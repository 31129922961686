<template>
  <div class="reting">
    <label class="radio">
      <input type="radio" name="reting" value="1" disabled />
      <span class="reting-number">1</span>
    </label>
    <label class="radio">
      <input type="radio" name="reting" value="2" disabled />
      <span class="reting-number">2</span>
    </label>
    <label class="radio">
      <input type="radio" name="reting" value="3" disabled />
      <span class="reting-number">3</span>
    </label>
    <label class="radio">
      <input type="radio" name="reting" value="4" disabled />
      <span class="reting-number">4</span>
    </label>
    <label class="radio">
      <input type="radio" name="reting" value="5" disabled />
      <span class="reting-number">5</span>
    </label>
    <label class="radio">
      <input type="radio" name="reting" value="6" disabled />
      <span class="reting-number">6</span>
    </label>
    <label class="radio">
      <input type="radio" name="reting" value="7" disabled />
      <span class="reting-number">7</span>
    </label>
    <label class="radio">
      <input type="radio" name="reting" value="8" disabled />
      <span class="reting-number">8</span>
    </label>
    <label class="radio">
      <input type="radio" name="reting" value="9" disabled />
      <span class="reting-number">9</span>
    </label>
    <label class="radio">
      <input type="radio" name="reting" value="10" disabled />
      <span class="reting-number">10</span>
    </label>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* star type-range css */
.reting {
  display: flex;
  justify-content: space-between;
}

.reting .radio {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #e0e0e0;
  border: 1px solid rgba(0, 0, 0, 0.048);
}

.reting .radio .reting-number {
  margin: 5px;
  margin-top: 7px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background: none;
}
</style>
