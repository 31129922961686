<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Update Data Quisonary</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Input Quisonary</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="sendKuesioner"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>
                        <div class="tombol">
                          <button
                            class="button h-button is-danger is-raised"
                            @click.prevent="hapus"
                            type="is-success"
                            outlined
                          >
                            Hapus
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Pertayaan</label>

                            <input
                              @blur="$v.form.question.$touch()"
                              v-model="form.question"
                              type="text"
                              class="input is-link"
                              placeholder="Masukan Pertayaan"
                            />
                            <div v-if="$v.form.question.$error">
                              <span
                                v-if="!$v.form.question.required"
                                class="help is-danger"
                                >Pertayaan Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Type Jawaban</label>
                            <div class="grup">
                              <b-field type="is-link">
                                <b-select
                                  @blur="$v.form.type.$touch()"
                                  v-model="form.type"
                                  placeholder="Masukan Type Jawaban"
                                  expanded
                                >
                                  <!-- <option value="checkbox">Check Box</option> -->
                                  <option value="radio">Radio</option>
                                  <option value="text">Text</option>
                                  <option value="range">Range</option>
                                </b-select>
                              </b-field>

                              <div v-if="$v.form.type.$error">
                                <span
                                  v-if="!$v.form.type.required"
                                  class="help is-danger"
                                  >Type Harus Diisi</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Star Type jawaban -->
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <div class="jawaban">
                              <!--  star type Multiple Choice -->
                              <div
                                v-if="form.type === 'radio'"
                                class="type-radio"
                              >
                                <label class="label">Jawaban</label>
                                <div class="field is-grouped">
                                  <div class="control content">
                                    {{ form.type.radio }}
                                  </div>
                                </div>
                              </div>

                              <!-- star type  text -->
                              <div
                                v-if="form.type === 'text'"
                                class="type-text"
                              >
                                <textarea
                                  class="textarea is-link"
                                  placeholder="e.g. text area"
                                  disabled
                                ></textarea>
                              </div>

                              <!-- end type  text -->
                              <!-- star type range-->
                              <div
                                v-if="form.type === 'range'"
                                class="type-range"
                              >
                                <TypeRange />
                              </div>

                              <!-- end type  range -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- End Type Jawaban -->
                    </div>
                  </div>
                  <!-- end form -->
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import TypeRange from "./TypeRange.vue";

export default {
  name: "FormUpdateQuestionary",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    TypeRange,
  },
  data() {
    return {
      radio: "",
      form: {
        id: null,
        question: null,
        type: null,
      },
    };
  },
  validations: {
    form: {
      question: { required },
      type: { required },
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchQuestionById(this.id).then((resp) => {
      this.form = resp;
    });
  },
  computed: {
    ...mapState({
      content: (state) => state.question.items,
    }),
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
    ...mapActions("question", [
      "fetchQuestion",
      "fetchQuestionById",
      "updateQuestion",
      "deletQuestionById",
    ]),
    sendKuesioner() {
      this.updateQuestion(this.form)
        .then(() => {
          this.$toasted.success("Rubah Data Kuesionary Berhasil", {
            duration: 3000,
          });
          this.$router.push("/dashboard/kuesioner");
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
    hapus() {
      this.$swal({
        title: "Apakah anda yakin akan menghapus kuesionary tersebut?!",
        text: "Setelah dihapus anda tidak dapat mengembalikannya!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "YA, HAPUS!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$store.dispatch("question/deletQuestionById", this.id);
          this.$router.push("/dashboard/kuesioner");
          this.$toasted.success("Hapus Data Kuesionary Berhasil", {
            duration: 3000,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .tombol {
    margin-top: 10px;
    width: 300px;
  }
}
</style>
