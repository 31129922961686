import axiosInstance from "../../conf/axiosAdmin";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {},
  actions: {
    fetchQuestion({ state, commit }) {
      commit("setItems", { resource: "question", items: {} }, { root: true });
      return axiosInstance
        .get("/api/survey/questionnaire/search")
        .then((res) => {
          const question = res.data.data;
          // console.log(question);
          commit(
            "setItems",
            { resource: "question", items: question },
            { root: true }
          );
          return state.items;
        });
    },
    searchQuestion({ state, commit }, params) {
      commit("setItems", { resource: "question", items: {} }, { root: true });
      return axiosInstance
        .get(`/api/survey/questionnaire/search?${params}`)
        .then((res) => {
          const results = res.data;
          commit(
            "setItems",
            { resource: "question", items: results },
            { root: true }
          );
          return state.items;
        });
    },
    addQuestion(context, payload) {
      const headers = {
        "Content-Type": "application/json",
      };
      return axiosInstance
        .post("/api/survey/questionnaire/create", payload, {
          headers: headers,
        })
        .then((res) => res.data);
    },
    updateQuestion(context, payload) {
      const headers = {
        "Content-Type": "application/json",
      };
      return axiosInstance.put(
        `/api/survey/questionnaire/update/${payload.id}`,
        payload,
        {
          headers: headers,
        }
      );
    },
    fetchQuestionById({ state, commit }, Id) {
      commit("setItem", { resource: "question", item: {} }, { root: true });
      return axiosInstance
        .get(`/api/survey/questionnaire/view/${Id}`)
        .then((res) => {
          const question = res.data;
          commit(
            "setItem",
            { resource: "question", item: question },
            { root: true }
          );
          return state.item;
        });
    },
    deletQuestionById({ state, commit }, id) {
      commit("setItem", { resource: "question", item: [] }, { root: true });
      return axiosInstance
        .delete(`/api/survey/questionnaire/remove/${id}`)
        .then((res) => {
          const question = res.data;
          commit(
            "setItem",
            { resource: "question", item: question },
            { root: true }
          );
          return state.item;
        });
    },
  },
  mutations: {},
};
