<template>
  <div class="modal h-modal is-big" :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background h-modal-close"></div>
    <div class="modal-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <h3>Informasi Pos Dinas</h3>
          <button class="close" aria-label="close" @click="closed">
            <i class="fas fa-window-close"></i>
          </button>
        </header>
        <div class="modal-card-body" v-if="isLoading">
          <app-spinner />
        </div>
        <div class="modal-card-body" v-else>
          <div class="tabs-wrapper">
            <div class="tabs">
              <ul>
                <li v-bind:class="{ 'is-active': isActive == 'Detail Pos' }">
                  <a v-on:click="isActive = 'Detail Pos'">Detail Pos</a>
                </li>
                <li v-bind:class="{ 'is-active': isActive == 'pod' }">
                  <a v-on:click="isActive = 'pod'">Ketersediaan Data Pos</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'Detail Pos' }"
          >
            <div class="detail">
              <div class="card">
                <section class="columns">
                  <div class="column is-12">
                    <div class="cards scroll-page">
                      <div class="columns">
                        <div class="column is-7 scroll">
                          <div class="header">
                            <h2 class="title is-4 is-narrow">Identitas Pos</h2>
                          </div>

                          <div class="card-content">
                            <div class="content">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>Nama Pos</td>
                                    <td>:</td>
                                    <td>{{ data.namaPos }}</td>
                                  </tr>
                                  <tr>
                                    <td>Lokasi Pos</td>
                                    <td>:</td>
                                    <td>{{ data.lokasiPos }}</td>
                                  </tr>

                                  <tr>
                                    <td>DAS</td>
                                    <td>:</td>
                                    <td>
                                      {{ data.namaDas }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Sungai</td>
                                    <td>:</td>
                                    <td>
                                      {{ data.namaSungai }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Jenis Pos</td>
                                    <td>:</td>
                                    <td>
                                      {{ data.jenisPos }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Dinas Pengelola</td>
                                    <td>:</td>
                                    <td>
                                      {{ data.dinasPengelola }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="column gambar">
                          <div class="card is-4">
                            <div class="card-image">
                              <figure class="image is-3by2">
                                <img src="../../assets/img/sungai.jpg" alt="" />
                              </figure>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'pod' }"
          >
            <section>
              <div class="datatable-toolbar">
                <div class="buttons">
                  <button
                    class="button h-button is-success is-raised"
                    @click.prevent="cariDataTahunan"
                  >
                    <i class="fas fa-search"></i>
                    Cari Data
                  </button>
                </div>
              </div>
            </section>
            <br />
            <section class="columns">
              <div class="column is-12 tma">
                <div class="card-debit">
                  <div class="card">
                    <div class="card-header"></div>
                    <div class="card-conten chart">
                      <v-chart autoresize class="chart" :option="awlr" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <br />
            <section class="channel">
              <div class="tabel-channel">
                <div class="table-wrapper" v-if="isLoading">
                  <app-spinner />
                </div>
                <div class="table-wrapper" v-else>
                  <b-table
                    class="table is-datatable is-hoverable table-is-bordered"
                    :data="isEmpty ? [] : dataTahunan"
                    :columns="columnsData"
                    focusable
                    paginated
                    :total="totalData"
                    :per-page="perPage"
                    @page-change="onPageChange"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    :default-sort-direction="defaultSortOrder"
                    :default-sort="[sortField, sortOrder]"
                    @sort="onSort"
                    icon-pack="fas"
                    icon-left="chevron-right"
                  >
                    <b-table-column
                      field="tanggal"
                      label="Tanggal"
                      v-slot="props"
                    >
                      {{ props.row.tanggal }}
                    </b-table-column>

                    <b-table-column field="ch1" label="TMA (cm)" v-slot="props">
                      {{ props.row.ch1 }}
                    </b-table-column>

                    <template #empty>
                      <div class="has-text-centered">Tidak Ada Data</div>
                    </template>
                  </b-table>
                </div>
              </div>
            </section>
            <br />
          </div>
        </div>
        <div class="modal-card-foot is-right">
          <button @click="closed" class="button is-normal is-focused tutup">
            <span>Tutup</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VChart from "vue-echarts";
import AppSpinner from "../shared/AppSpinner.vue";
export default {
  name: "ModalPosTelemetry",
  components: {
    AppSpinner,
    VChart,
  },
  data() {
    return {
      selected: "Detail Pos",
      isActive: "Detail Pos",
      registerId: {},
      isOpen: false,
      data: null,
      dataTahunan: [],
      totalData: 0,
      perPage: 10,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      columnsData: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "ch1",
          label: "TMA (cm)",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: true,
      hasMobileCards: true,
      awlr: {},
      waspada: 5,
      siaga: 50,
      awas: 60,
      tinggiTanggul: 150,
    };
  },
  created() {
    this.isLoading = true;
    this.awlr = {
      title: {
        left: "1%",
      },
      tooltip: {
        trigger: "axis",
      },
      grid: {
        left: "7%",
        right: "15%",
        bottom: "10%",
        top: "10%",
      },
      xAxis: {
        // data: resp.map((r) => r.tanggal).reverse(),
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} cm",
        },
      },

      dataZoom: [
        {
          startValue: "1970-01-01",
        },
        {
          type: "inside",
        },
      ],
      visualMap: {
        top: 10,
        right: 10,
        pieces: [
          {
            lte: this.waspada,
            color: "#63b208",
          },
          {
            lte: this.siaga,
            color: "#f7f711",
          },
          {
            lte: this.awas,
            color: "#FD0100",
          },
          {
            gt: this.tinggiTanggul,
            color: "#7f8c8d",
          },
        ],
        outOfRange: {
          color: "#e74c3c",
        },
      },
      series: {
        name: "TMA",
        type: "line",
        // data: resp.map((r) => r.tma).reverse(),
      },
    };
  },
  computed: {
    ...mapState({
      landingPosDinas: (state) => state.landingPosDinas.items,
    }),
  },
  methods: {
    ...mapActions("landingPosDinas", ["fetchLandingPosById"]),
    setSelected(tab) {
      this.selected = tab;
    },
    appendData(n) {
      this.fetchLandingPosById(n).then((resp) => {
        this.data = resp;
        this.isLoading = false;
      });
    },

    cariDataTahunan() {
      this.$router.push(`/caridata/datatahunan/${this.registerId}`);
    },

    closed() {
      this.isOpen = false;
      this.isActive = "Detail Pos";
      this.isLoading = true;
    },
    // channnel table

    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },

    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    increment() {
      this.page += 1;
    },

    decrement() {
      this.page -= 1;
    },
  },
  mounted() {
    this.$root.$on("pos-dinas", this.appendData);
    this.$root.$on("pos-dinas", () => {
      if (!this.isOpen) {
        this.isOpen = !this.isOpen;
      }
    });
  },

  beforeDestroy() {
    this.$root.$off("pos-dinas", this.registerId);
  },
};
</script>

<style scoped>
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  animation: swingIn 1.2s ease-in-out;
}

.modal-content {
  width: 100%;
  min-width: 1200px;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.header {
  margin-left: 30px;
}

.gambar {
  margin: auto;
  margin-right: 10px;
}
button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}
button.tutup {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  /* width: 50px; */
  padding: 14px;
  font-size: 16px;
  color: white;
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}
.chart {
  height: 400px;
  width: 100%;
}

.fas {
  margin-right: 10px;
  margin-left: -10px;
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    min-width: 350px;
  }
  .chart {
    margin-left: 2px;
  }
  .gambar {
    margin-left: 10px;
  }
}
.banting-kanan {
  float: right;
}
.tombol {
  padding-bottom: 10px;
}
</style>
