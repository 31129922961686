import axiosInstance from "../../conf/axiosAdmin";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {},
  actions: {
    fetchLandingQuestion({ state, commit }) {
      commit(
        "setItems",
        { resource: "landingQuestion", items: {} },
        { root: true }
      );
      return axiosInstance
        .get("/api/landing/survey/questionnaire/search")
        .then((res) => {
          const questions = res.data.data;
          // console.log(question);
          commit(
            "setItems",
            { resource: "landingQuestion", items: questions },
            { root: true }
          );
          return state.items;
        });
    },
  },
  mutations: {},
};
