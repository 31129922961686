<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Input Data Quisonary</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Input Quisonary</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="sendKuesioner"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Pertayaan</label>

                            <input
                              @blur="$v.form.question.$touch()"
                              v-model="form.question"
                              type="text"
                              class="input is-link"
                              placeholder="Masukan Pertayaan"
                            />
                            <div v-if="$v.form.question.$error">
                              <span
                                v-if="!$v.form.question.required"
                                class="help is-danger"
                                >Pertayaan Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Type Jawaban</label>
                            <div class="grup">
                              <b-field type="is-link">
                                <b-select
                                  @blur="$v.form.type.$touch()"
                                  v-model="form.type"
                                  placeholder="Masukan Type Jawaban"
                                  expanded
                                >
                                  <!-- <option value="checkbox">Check Box</option> -->
                                  <option value="radio">Radio</option>
                                  <option value="text">Text</option>
                                  <option value="range">Range</option>
                                </b-select>
                              </b-field>

                              <div v-if="$v.form.type.$error">
                                <span
                                  v-if="!$v.form.type.required"
                                  class="help is-danger"
                                  >Type Harus Diisi</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Star Type jawaban -->
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <div class="jawaban">
                              <!--  star type Multiple Choice -->
                              <div
                                v-if="form.type === 'radio'"
                                class="type-radio"
                              >
                                <label class="label">Jawaban</label>
                                <div class="field is-grouped">
                                  <div class="control is-expanded">
                                    <input
                                      @keyup.enter="addNewAnswer"
                                      v-model="newAnswers"
                                      class="input is-link"
                                      type="text"
                                      placeholder="Input Jawaban"
                                    />
                                  </div>
                                  <div class="control">
                                    <button
                                      @click="addNewAnswer"
                                      class="button is-link"
                                    >
                                      <span>+ Tambah</span>
                                    </button>
                                  </div>
                                </div>

                                <div
                                  class="inputan"
                                  v-for="(answer, index) in answers"
                                  :key="index.id"
                                >
                                  <div class="field has-addons item-jawaban">
                                    <div class="control is-expanded">
                                      <input
                                        v-model="answer.title"
                                        class="input is-link"
                                        type="text"
                                        disabled
                                      />
                                    </div>
                                    <div class="control">
                                      <button
                                        class="button is-danger hapus-item"
                                        @click="hapus(index)"
                                      >
                                        &times;
                                      </button>
                                    </div>
                                  </div>

                                  <!-- <div class="control content">
                                    <b-radio
                                      v-model="radio"
                                      name="name"
                                      native-value="Sangat Puas"
                                      disabled
                                    >
                                      Sangat Puas
                                    </b-radio>
                                    <b-radio
                                      v-model="radio"
                                      name="name"
                                      native-value="Puas"
                                      disabled
                                    >
                                      Puas
                                    </b-radio>
                                    <b-radio
                                      v-model="radio"
                                      name="name"
                                      native-value="Tidak Puas"
                                      disabled
                                    >
                                      Tidak Puas
                                    </b-radio>
                                  </div> -->
                                </div>
                              </div>

                              <!-- end type  Multiple Choice -->

                              <!-- star type  checkbox -->
                              <!-- <div
                                v-if="form.type === 'checkbox'"
                                class="type-checkbox"
                              >
                                <label class="label">Jawaban</label>
                                <div class="field is-grouped">
                                  <div class="control is-expanded">
                                    <input
                                      @keyup.enter="addNewAnswer"
                                      v-model="newAnswers"
                                      class="input is-link"
                                      type="text"
                                      placeholder="Input Jawaban"
                                    />
                                  </div>
                                  <div class="control">
                                    <button
                                      @click="addNewAnswer"
                                      class="button is-link"
                                    >
                                      <span>+ Tambah</span>
                                    </button>
                                  </div>
                                </div>

                                <div
                                  class="inputan"
                                  v-for="(answer, index) in answers"
                                  :key="index.id"
                                >
                                  <div class="field has-addons item-jawaban">
                                    <div class="control is-expanded">
                                      <input
                                        v-model="answer.title"
                                        class="input is-link"
                                        type="text"
                                        disabled
                                      />
                                    </div>
                                    <div class="control">
                                      <button
                                        class="button is-danger hapus-item"
                                        @click="hapus(index)"
                                      >
                                        &times;
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div> -->

                              <!-- end type  checkbox -->
                              <!-- star type  text -->
                              <div
                                v-if="form.type === 'text'"
                                class="type-text"
                              >
                                <textarea
                                  class="textarea is-link"
                                  placeholder="e.g. text area"
                                  disabled
                                ></textarea>
                              </div>

                              <!-- end type  text -->
                              <!-- star type range-->
                              <div
                                v-if="form.type === 'range'"
                                class="type-range"
                              >
                                <TypeRange />
                              </div>

                              <!-- end type  range -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- End Type Jawaban -->
                    </div>
                  </div>
                  <!-- end form -->
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import TypeRange from "./TypeRange.vue";

export default {
  name: "FormInputKontenInformasi",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    TypeRange,
  },
  data() {
    return {
      newAnswers: "",
      answerId: 1,
      answers: [],
      radio: "",
      form: {
        question: null,
        type: null,
      },
    };
  },
  validations: {
    form: {
      question: { required },
      type: { required },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
    addNewAnswer() {
      if (this.newAnswers.trim().length == 0) {
        return;
      }
      this.answers.push({
        id: this.answerId,
        title: this.newAnswers,
      });
      (this.newAnswers = ""), this.answerId++;
    },
    hapus(index) {
      this.answers.splice(index, 1);
    },
    sendKuesioner() {
      this.$store
        .dispatch("question/addQuestion", this.form)
        .then(() => this.$router.push("/dashboard/kuesioner"));
      this.$toasted.success("Tambah Data Questionary Berhasil", {
        duration: 5000,
      });
    },
  },
};
</script>

<style scoped>
.grup {
  display: flex;
}
.text {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  /* margin-left: 10px; */
}
/* star type-range css */
.reting {
  display: flex;
  justify-content: space-between;
}

.reting .radio {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #e0e0e0;
  border: 1px solid rgba(0, 0, 0, 0.048);
}

.reting .radio .reting-number {
  margin: 5px;
  margin-top: 7px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background: none;
}

.item-jawaban {
  width: 400px;
  height: 40px;
  margin-top: 10px;
}
.hapus-item {
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  color: #e0e0e0;
}

.hapus-item:hover {
  color: #212c5f;
}
</style>
