<template>
  <div class="modal-kepuasan">
    <div :class="['modal', { 'is-active': buka }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <!-- <header class="modal-card-head">
           
                   
         <button
            @click="buka = false"
            class="delete"
            aria-label="close"
          ></button>
        </header> -->
        <div class="modal-card-body">
          <section v-show="activeStep === 1">
            <form :class="animation">
              <div class="logo">
                <img src="@/assets/image/logoPu.png" style="max-height: 55px" />
              </div>

              <div class="field">
                <div class="control">
                  <span class="modal-card-title"
                    >Bantu kami meningkatkan layanan</span
                  >
                  <p class="subtitle">
                    Seberapa puaskah Anda dengan layanan yang kami berikan?
                  </p>
                </div>
              </div>

              <div class="field is-grouped is-grouped-centered">
                <div class="control">
                  <label class="radio">
                    <img
                      class="gambar"
                      src="@/assets/image/sad.png"
                      style="max-height: 40px"
                    />
                    <input
                      v-model="form.score"
                      @blur="$v.form.score.$touch()"
                      type="radio"
                      name="kategori"
                      value="kecewa"
                    />
                    <p class="kategori">Kecewa</p>
                  </label>
                  <label class="radio">
                    <img
                      src="@/assets/image/expressionless.png"
                      style="max-height: 40px"
                    />
                    <input
                      v-model="form.score"
                      @blur="$v.form.score.$touch()"
                      name="kategori"
                      type="radio"
                      value="biasa"
                    />
                    <p class="kategori">Biasa</p>
                  </label>
                  <label class="radio">
                    <img
                      class=""
                      src="@/assets/image/smiling-face.png"
                      style="max-height: 40px"
                    />
                    <input
                      v-model="form.score"
                      @blur="$v.form.score.$touch()"
                      type="radio"
                      name="kategori"
                      value="puas"
                    />
                    <p class="kategori">Puas</p>
                  </label>
                </div>
                <div v-if="$v.form.score.$error" class="form-error">
                  <span v-if="!$v.form.score.required" class="help is-danger"
                    >Reting Harus Diisi</span
                  >
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Nama</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.nama"
                      @blur="$v.form.nama.$touch()"
                      class="input"
                      type="text"
                      placeholder="Nama"
                    />
                    <span class="icon is-small is-left">
                      <i class="fal fa-user"></i>
                    </span>
                    <div v-if="$v.form.nama.$error" class="form-error">
                      <span v-if="!$v.form.nama.required" class="help is-danger"
                        >Nama Harus Diisi</span
                      >
                      <span
                        v-if="!$v.form.nama.minLength"
                        class="help is-danger"
                        >Nama minimun 3 karakter</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">E-mail</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.email"
                      @blur="$v.form.email.$touch()"
                      class="input"
                      type="email"
                      placeholder="Email"
                    />
                    <span class="icon is-small is-left">
                      <i class="far fa-envelope"></i>
                    </span>
                    <div v-if="$v.form.email.$error" class="form-error">
                      <span
                        v-if="!$v.form.email.required"
                        class="help is-danger"
                        >Surel Harus Diisi</span
                      >
                      <span v-if="!$v.form.email.email" class="help is-danger"
                        >SurelTidak Sesuai</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Pekerjaan</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.pekerjaan"
                      @blur="$v.form.pekerjaan.$touch()"
                      class="input"
                      type="text"
                      placeholder="Pekerjaan"
                    />
                    <span class="icon is-small is-left">
                      <i class="fal fa-user-hard-hat"></i>
                    </span>
                    <div v-if="$v.form.pekerjaan.$error" class="form-error">
                      <span
                        v-if="!$v.form.pekerjaan.required"
                        class="help is-danger"
                        >Pekerjaan Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Instansi atau Perusahaan</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.instansi"
                      @blur="$v.form.instansi.$touch()"
                      class="input"
                      type="text"
                      placeholder="Instansi atau Perusahaan"
                    />
                    <span class="icon is-small is-left">
                      <i class="fal fa-briefcase"></i>
                    </span>
                    <div v-if="$v.form.instansi.$error" class="form-error">
                      <span
                        v-if="!$v.form.instansi.required"
                        class="help is-danger"
                        >Instansi atau Perusahaan Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!--  <div class="field">
                <div class="control">
                  <label class="label">captcha Code</label>
                </div>
              </div>
              <div class="field is-grouped">
                <captcha-code
                  :captcha.sync="code"
                  @on-change="handleChange"
                  ref="captcha"
                ></captcha-code>
                <input
                  v-model="capcay"
                  @blur="$v.capcay.$touch()"
                  class="input"
                  type="text"
                  placeholder="captcha Code"
                />
                <vue-hcaptcha sitekey="ec450111-4d5e-4736-801c-e9023cec354a" @verify="onVerify" v-model="form.robot"></vue-hcaptcha> 
              </div> -->

              <!-- <div class="field is-group tombol">
                  <div class="control btn">
                  <button
                    @click="buka = false"
                    class="button is-normal is-danger is-focused"
                  >
                    <span> Batal </span>
                  </button>
                </div>
                <div class="control btn1">
                  <button
                    :disabled="isFormInvalid"
                    @click="buka = false"
                    @click.prevent="sendKepuasan"
                    class="button is-normal is-link is-focused"
                  >
                    <span> Kirim </span>
                  </button>
                  <button
                    class="button is-info is-focused"
                    v-if="activeStep != totalStep"
                    :disabled="isFormInvalid"
                    @click="nextStep"
                  >
                    Selanjut Nya
                  </button>
                </div>
              </div> -->
            </form>
          </section>

          <section class="kuesioner" v-show="activeStep === 2">
            <!-- <form :class="animation">
              <ModalKuesioner />
            </form> -->
            <article>
              <form :class="animation" class="form">
                <div class="input-fields">
                  <div
                    class="input-container"
                    v-for="(kuesioner, index) in questions"
                    :key="index"
                  >
                    <div class="kuesioner">
                      {{ kuesioner.question }}
                    </div>

                    <!-- Star Type jawaban -->
                    <div class="column is-12">
                      <div class="field">
                        <div class="control">
                          <div class="jawaban">
                            <!--  star type Multiple Choice -->
                            <div
                              v-if="kuesioner.type === 'radio'"
                              class="type-radio"
                            >
                              <label class="label">Jawaban</label>
                              <div class="field is-grouped">
                                <div class="control content">
                                  <b-radio
                                    v-model="radio"
                                    name="name"
                                    native-value="Sangat Puas"
                                  >
                                    <span class="text-jawaban"
                                      >Sangat Puas</span
                                    >
                                  </b-radio>
                                  <b-radio
                                    v-model="radio"
                                    name="name"
                                    native-value="Puas"
                                  >
                                    <span class="text-jawaban">Puas</span>
                                  </b-radio>
                                  <b-radio
                                    v-model="radio"
                                    name="name"
                                    native-value="Tidak Puas"
                                  >
                                    <span class="text-jawaban">Tidak Puas</span>
                                  </b-radio>
                                </div>
                              </div>
                            </div>

                            <!-- star type  text -->
                            <div
                              v-if="kuesioner.type === 'text'"
                              class="type-text"
                            >
                              <b-input
                                placeholder="maksimal 200 huruf"
                                maxlength="200"
                                type="textarea"
                              ></b-input>
                            </div>

                            <!-- end type  text -->
                            <!-- star type range-->
                            <div
                              v-if="kuesioner.type === 'range'"
                              class="type-range"
                            >
                              <div class="reting">
                                <label class="radio">
                                  <input type="radio" name="reting" value="1" />
                                  <span class="reting-number">1</span>
                                </label>
                                <label class="radio">
                                  <input type="radio" name="reting" value="2" />
                                  <span class="reting-number">2</span>
                                </label>
                                <label class="radio">
                                  <input type="radio" name="reting" value="3" />
                                  <span class="reting-number">3</span>
                                </label>
                                <label class="radio">
                                  <input type="radio" name="reting" value="4" />
                                  <span class="reting-number">4</span>
                                </label>
                                <label class="radio">
                                  <input type="radio" name="reting" value="5" />
                                  <span class="reting-number">5</span>
                                </label>
                                <label class="radio">
                                  <input type="radio" name="reting" value="6" />
                                  <span class="reting-number">6</span>
                                </label>
                                <label class="radio">
                                  <input type="radio" name="reting" value="7" />
                                  <span class="reting-number">7</span>
                                </label>
                                <label class="radio">
                                  <input type="radio" name="reting" value="8" />
                                  <span class="reting-number">8</span>
                                </label>
                                <label class="radio">
                                  <input type="radio" name="reting" value="9" />
                                  <span class="reting-number">9</span>
                                </label>
                                <label class="radio">
                                  <input
                                    type="radio"
                                    name="reting"
                                    value="10"
                                  />
                                  <span class="reting-number">10</span>
                                </label>
                              </div>
                            </div>

                            <!-- end type  range -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
              </form>
            </article>
          </section>
          <section>
            <div class="field is-group tombol">
              <button
                class="button is-danger is-focused"
                v-if="activeStep == 1"
                @click="buka = false"
              >
                Batal
              </button>
              <button
                class="button is-info is-focused"
                v-if="activeStep != totalStep"
                @click.prevent="nextStep"
                :disabled="isFormInvalid"
              >
                Selanjut Nya
              </button>
              <button
                class="button is-info is-focused"
                v-if="activeStep == 2"
                @click.prevent="send"
                @click="buka = false"
                :disabled="isFormInvalid"
              >
                Kirim
              </button>
            </div>
          </section>
        </div>

        <!-- <footer class="modal-card-foot">
          
        </footer> -->
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
// import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
// import CaptchaCode from "vue-captcha-code";
// import ModalKuesioner from "../landing/ModalKuesioner";
export default {
  components: {
    // VueHcaptcha,
    // CaptchaCode,
    // ModalKuesioner,
  },
  props: {
    buka: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      code: "",
      radio: "",
      // capcay: null,
      isSesuai: true,
      form: {
        score: null,
        nama: null,
        email: null,
        pekerjaan: null,
        instansi: null,
      },
      activeSteps: {},
      activeStep: 1,
      totalStep: 2,
      animation: "animate-in",
      slide: "animate-slide",
    };
  },
  validations: {
    form: {
      score: {
        required,
      },
      nama: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      pekerjaan: {
        required,
      },
      instansi: {
        required,
      },
    },
    // capcay: {
    //   required,
    // },
  },
  created() {
    this.fetchLandingQuestion();
  },
  computed: {
    ...mapState({
      questions: (state) => state.landingQuestion.items,
    }),
    isFormInvalid() {
      // return this.$v.form.$invalid;
      return this.$v.$invalid;
    },
    // fileName: (state) => state.landingGeneratePdf.item,
  },
  methods: {
    ...mapActions("landingQuestion", ["fetchLandingQuestion"]),
    send() {
      this.$store.dispatch("landingKepuasan/createLandingKepuasan", this.form);
      // .then(() => this.$emit("onLoad"));
      this.form = {};
    },
    // sendKepuasan() {
    //   if (this.capcay == this.code) {
    //     this.$store.dispatch(
    //       "landingKepuasan/createLandingKepuasan",
    //       this.form
    //     );
    //     then(() => this.$emit("onLoad"));
    //     this.form = {};
    //     this.handleClick();
    //   } else {
    //     this.$toasted.error("Captcha Code Tidak sama", { duration: 3000 });
    //     this.form = {};
    //     this.capcay = null;
    //     this.handleClick();
    //   }
    // },

    nextStep() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
      }, 600);
    },

    // handleClick() {
    //   this.$refs.captcha.refreshCaptcha();
    // },
    handleChange(code) {
      console.log("code:", code);
    },
  },
};
</script>

<style scoped>
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  animation: swing 1.2s ease-in-out;
}

@keyframes swing {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0px);
    transition: all 0.5s;
  }
}

.logo {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}

.modal-card-title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 30px;
}

.gambar {
  margin-left: 5px;
}

article {
  display: flex;
  margin-bottom: 10px;
  min-width: 500px;
  min-height: 500px;
  perspective: 1000px;
}
.pertayaan {
  display: flex;
  flex-direction: column;
  width: 720px;
  background-color: #fff;
}
.form {
  margin-top: 20px;
  margin-left: 20px;
  min-height: 400px;
  height: 100%;
  font-size: 16px;
  font-weight: 500;
}
.text-jawaban {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.kuesioner .control {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.label .radio {
  margin-top: 10px;
  margin-right: -30px;
}

.reting {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: -15px;
}
.reting .radio .reting-number:hover {
  background: #2d6efd;
  transition: all 1s;
  color: #fff;
}
.reting .radio .reting-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e0e0e0;
  color: #212c5f;
  font-weight: 600;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  transition: all 1s;
}
.reting input[type="radio"] {
  display: none;
}
input[type="radio"]:checked ~ .reting-number {
  position: relative;
  font-weight: 700;
  color: #fff;
  border: 1px solid #212c5f51;
  background: #2d6efd;
  transition: all 1s;
}

.button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  width: 135px;
  letter-spacing: 1px;
  margin-left: 10px;
}

input[type="radio"] {
  visibility: hidden;
}

input[type="radio"]:checked ~ .kategori {
  color: #2d6efd;
}

.radio {
  margin-right: 50px;
}

.is-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 50px;
}

/* star animation slide */
.animate-in {
  transform-origin: left;
  animation: in 1.2s ease;
  overflow: hidden;
}
.animate-out {
  transform-origin: right;
  animation: out 1.2s ease;
  overflow: hidden;
}
.animate-slide {
  transform-origin: left;
  animation: slide 1.2s ease-in-out;
  overflow: hidden;
}

@keyframes in {
  0% {
    transform: translateX(15%);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
    transition: all 1.2s;
  }
}

@keyframes out {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(15%);
    opacity: 0;
    transition: all 1.2s;
  }
}

@keyframes slide {
  0% {
    transform: translateX(50%);
    opacity: 0;
    transition: all 1s;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
    transition: all 1.2s;
  }
}
/* .kuesioner {
  width: 600px;
} */
/* end display tampilan dan animasi */

@media (max-width: 768px) {
  .modal-card {
    height: 600px;
    width: 350px;
  }

  .button {
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    width: 135px;
    margin-bottom: 5px;
  }

  .is-grouped {
    margin-left: 70px;
  }

  .is-group {
    display: flex;
    margin-left: 10px;
    padding-top: 30px;
    padding-bottom: 50px;
  }
}
</style>
